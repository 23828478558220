import { useEffect, useState } from 'react'

export function GetCountryCode() {
  const [info, setInfo] = useState('');
  const fetchUrl = '//websvc.jellybelly.com/geoip/check?ip='

  useEffect(() => {
    fetch(fetchUrl)
      .then((response) => response.json())
      .then((responseJson) => {
        setInfo(responseJson);
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  return info;
}