// import { cardInfo } from './images';
import { GetCountryCode } from '../../GetCountryCode';

function Card(props) {
  return (
    <>
      <div className={ props.cardInfo.imgClass }>
        <div className="logo-wrapper">
          <img src={ props.cardInfo.src1 } alt={ props.cardInfo.alt1 } className={ props.cardInfo.class1 } />
        </div>
        <div className={ props.idx === 1 ? "link-container bb-link-cont" : "link-container" }>
          <img src={ props.cardInfo.src2 } alt={ props.cardInfo.alt2 } className={ props.cardInfo.class2 } />
          <a href={ props.cardInfo.anchorHref } className={ props.cardInfo.anchorClass }>TAKE THE CHALLENGE</a>
        </div>
        <div className="tag-wrapper">
          <img src={ props.cardInfo.src3 } alt={ props.cardInfo.alt3 } className={ props.cardInfo.class3 } />
        </div>
      </div>
    </>
  )
}

function Home() {
  const countryCode = GetCountryCode().country_code
  let logo, characters, alt;

  // it will be like this until November 2024...
  if (countryCode === "US") {
    logo = "https://cdn-tp1.mozu.com/9046-m1/cms/files/16cb88ec-acc5-469a-85c9-72c139f827c9";
    characters = "https://cdn-tp1.mozu.com/9046-m1/cms/files/b1f25a53-42eb-4a4b-ad1f-4deca8631cf9";
    alt = "Beanboozled 7th edition"
  } else {
    logo = 'https://cdn-tp1.mozu.com/9046-m1/cms/files/6fbe791c-169b-42d6-8f01-7d8d33f29b9e';
    characters = 'https://cdn-tp1.mozu.com/9046-m1/cms/files/15cf0e79-dc01-442a-822a-f0627b4d8864';
    alt = "Beanboozled 6th edition"
  }

  const cardInfo = [
    {
      imgClass: "fiery-five",
      src1: "https://cdn-tp1.mozu.com/9046-m1/cms/files/68c5ea4c-fbb1-42f9-855b-bfe6b1d8cb72",
      src2: "https://cdn-tp1.mozu.com/9046-m1/cms/files/d9407ddf-cdee-46e9-b700-1c66cfbf37d6",
      src3: "https://cdn-tp1.mozu.com/9046-m1/cms/files/c86f7167-4745-41dd-b577-524adef72fce",
      alt1: "BeanBoozled Fiery Five",
      alt2: "a caricature of a sweating red faced kid with their tongue out",
      alt3: "How hot can you handle?",
      class1: "bb-ff-logo",
      class2: "ff-face faces",
      class3: "tagline ff-tag",
      anchorHref: "https://www.jellybelly.com/beanboozled-fiery-five/c/890",
      anchorClass: "ff-btn challenge-btn"
    },
    {
      imgClass: "bb-challenge",
      src1: logo,
      src2: characters,
      src3: "https://cdn-tp1.mozu.com/9046-m1/cms/files/c5036a14-b271-4fba-9a8d-6de84b2349b1",
      alt1: alt,
      alt2: "a caricature of three people making faces and laughing",
      alt3: "are you brave enough?",
      class1: "bb-logo",
      class2: "bb-face faces",
      class3: "tagline bb-tag",
      anchorHref: "https://www.jellybelly.com/beanboozled-jelly-beans/c/341",
      anchorClass: "bb-btn challenge-btn"
    }
  ];

  if (countryCode === "US") {
    return (
      <>
        <div className="container">
          {cardInfo.map((cardInfo, idx) => (
            <Card cardInfo={cardInfo} key={idx} idx={idx} />
          ))}
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="container non-gdpr">
          {cardInfo.map((cardInfo, idx) => (
            idx !== 0 ? <Card cardInfo={cardInfo} key={idx} idx={idx} /> : ''
          ))}
        </div>
      </>
    )
  }
}

export default Home;